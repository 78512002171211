<template>
    <v-container class="py-8 px-6 pl-12" fluid>

        <tile-floating-title @submit="doSubmit" v-if="showFloating"></tile-floating-title>

        <v-row class="mt-16 following-row">
            <v-col class="mb-0">
                <router-view></router-view>
            </v-col>
        </v-row>

        <v-overlay :value="overlay" style="z-index: 199;">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
    </v-container>
</template>
<script>

export default {
    name: 'svc_staff',
    components: {
        'tileFloatingTitle': () => import('@/components/common/tile_floating_title.vue'),
        'popupAdd': () => import('@/components/svc/staff/popup_add.vue'),
    },
    data: () => ({
        overlay: false,
        showFloating: false
    }),
    created() {
        this.$store.commit('layoutFlag', true)
        this.$store.commit('menuId', 'svc_staff')
        this.$store.commit('svcVoiceIntro/setFloatingOptions', {
            title: '콜리 등록',
                page: 'svc_staff',
                css: {
                pixedWidth: -26,
                pixedMarginLeft: 1
            }
        })
    },
    mounted() {
        this.showFloating = true
    },
    methods: {
        doSubmit() {
            this.$store.dispatch('openConfirm', {
                message: '등록하신 정보를 저장하시겠습니까?',
                sub_message: '추가/삭제한 경우 이용기간을 계산하여 청구됩니다',
                va: this,
                okCb: function (va) {
                    va.overlay = true
                    va.$store.dispatch('svcStaff/submitList', {
                        scb: () => {
                            va.overlay = false
                            va.$store.dispatch('openAlert', {
                                va: va,
                                message: '저장 되었습니다',
                                closeCb: (va) => {
                                    va.$store.dispatch('svcStaff/getList', {
                                        scb: c => {
                                        }, fcb: err => {
                                        }
                                    })
                                }
                            })
                        },
                        fcb: (err) => {
                            va.overlay = false
                            if (va.$noSessionProc(err)) {
                                return
                            }
                            va.$store.dispatch('openAlert', {
                                message: '저장을 실패 했습니다',
                                sub_message: err
                            })
                        }
                    })
                }
            })
        }
    }
}
</script>